/* eslint-disable jsx-a11y/label-has-associated-control */
import "styles/pages/home.scss"

import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Slider from "react-slick"
import ReactPlayer from "react-player/lazy"
import scrollToElement from "scroll-to-element"

import Layout from "components/layout"
import Seo from "components/seo"
import Button from "components/Button/Button"
import { HomeBrands } from "components/Home"

const Index = ({ data }) => {
  const [videoPlay, setVideoPlay] = useState(false)

  const blogSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: false,
    slidesToShow: 2,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  }

  return (
    <Layout isHome>
      <Seo title="Home" />

      <section className="home-slider">
        <div
          className="home-slider__poster"
          style={{
            backgroundImage: `url('${data?.homeFields?.acfHome?.homeHeroVideoPoster?.sourceUrl}')`,
          }}
        ></div>
        <div
          className="home-slider__poster home-slider__poster--mobile"
          style={{
            backgroundImage: `url('${data?.homeFields?.acfHome?.homeHeroVideoPosterMobile?.sourceUrl}')`,
          }}
        ></div>
        <div className="home-slider__video">
          <ReactPlayer
            url={data.homeFields.acfHome.homeHeroVideo}
            width="100%"
            heigth="100%"
            playing={true}
            controls={false}
            muted={true}
            playsinline={true}
            loop={true}
          />
        </div>
        <button
          className="home-slider__scroll"
          onClick={() => scrollToElement("#content")}
        >
          Scroll to
        </button>
        <div className="home-slider__tabs">
          <Link to="/storv/">Størv</Link>
          <Link to="/studul/">STUĐUL</Link>
        </div>
        {data?.homeFields?.acfHome?.homeHeroExternalUrl && (
          <a
            href={data?.homeFields?.acfHome?.homeHeroExternalUrl}
            className="home-slider__url"
          >
            Url
          </a>
        )}
      </section>

      <span className="anchor" id="content"></span>
      <section className="home-history">
        <div className="container-fluid">
          <div className="global__header">
            <img
              className="header__logo"
              src={require("assets/images/home-history-header-ico.png").default}
              alt=""
            />
            <p>RASKA</p>
            <h2>{data?.homeFields?.acfHome?.homeAboutHeader}</h2>
          </div>
          <p className="home-history__desc">
            {data?.homeFields?.acfHome?.homeAboutDescription}
          </p>
          <div className="row">
            {data?.homeFields?.acfHome?.homeAboutTile.map((item, index) => (
              <div className="col-md-4" key={index}>
                <Link
                  to={`/${item.homeAboutTileUrlSlug}/`}
                  className="home-history__item"
                >
                  <img
                    className="img-fluid"
                    src={item.homeAboutTileImage.sourceUrl}
                    alt=""
                  />
                  <div className="home-history__item-inner">
                    <div>
                      <h5>{item.homeAboutTileLabel}</h5>
                      <h4>{item.homeAboutTileHeader}</h4>
                      <p>{item.homeAboutTileExcerpt}</p>
                    </div>
                    <span>...Síggj meira</span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      <HomeBrands
        homeProductsLogos={data.homeFields.acfHome.homeProductsLogos}
      />

      <section className="home-video">
        <div className="container-fluid">
          <div className="home-video__wrapper">
            <div className="home-video__video">
              {!videoPlay && (
                <>
                  <div
                    className="home-video__poster"
                    style={{
                      backgroundImage: `url('${
                        require("assets/images/home-video-poster.jpg").default
                      }')`,
                    }}
                  ></div>
                  <button
                    className="home-video__play"
                    onClick={() => setVideoPlay(true)}
                  >
                    Play
                  </button>
                </>
              )}
              <div className="embed-container">
                homeContentVideo
                <ReactPlayer
                  url={data.homeFields.acfHome.homeContentVideo}
                  width="100%"
                  heigth="100%"
                  playing={videoPlay}
                  controls={true}
                  muted={true}
                  playsinline={true}
                  loop={true}
                />
              </div>
            </div>
            <div className="home-video__inner">
              <h3>LUKSUS, KLASSISKT OG LÆTTI</h3>
              <p>
                Einki kann kappast við kakao. Heitt ella kalt – vit elska kakao
                og njóta tað bæði sum ein leskandi drykk ein summardag, flógvan
                hugna eitt vetrarkvøld og sum fastan gest til flest allar
                føðingardagar. Raska framleiðir fleiri ymisk sløg av kakao.
              </p>
              <Button to="/vorur/">Síggj meira</Button>
            </div>
          </div>
        </div>
      </section>

      <section className="home-recipes">
        <div className="container-fluid">
          <div className="global__header">
            <img
              className="header__logo"
              src={require("assets/images/home-recipes-header-ico.png").default}
              alt=""
            />
            <p>Vælsmakkandi</p>
            <h2>rættir viĐ okkara vørum</h2>
          </div>

          <div className="home-recipes__item">
            <div className="row">
              <div className="col-md-6">
                <img
                  className="img-fluid"
                  src={
                    require("assets/images/home-recipe-example-1.jpg").default
                  }
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <div className="home-recipes__item-inner">
                  <h3>
                    <span className="cat--desert"></span> CHURROS VIÐ ÍSI FRÁ
                    RØSKU
                  </h3>
                  <p>
                    Í Spania og Meiksiko eta tey churros til morgunmat. Eitt
                    lekkurt søtt deiggj, sum tú djúpsteikir og - sum vit kenna
                    tað best her heima - kann borðreiða við ísi frá Røsku.
                  </p>
                  <Button to="/churros-vid-isi-fra-rosku/">Síggj meira</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="home-recipes__item">
            <div className="row">
              <div className="col-md-6">
                <img
                  className="img-fluid"
                  src={
                    require("assets/images/home-recipe-example-2.jpg").default
                  }
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <div className="home-recipes__item-inner">
                  <h3>
                    <span className="cat--desert"></span> SJOKULÁTUKØKA VIÐ
                    KIRSUBERJAMOUSSE
                  </h3>
                  <p>
                    Spennandi smáar kakur við sjokulátabotni og
                    kirsuberjamousse. Ein perfekt samanseting av tí søta tunga
                    og tí lætta súrliga. Uppskriftin er gjørd í samstarvi við
                    Kvinnu.
                  </p>
                  <Button to="/sjokulatukoka-vid-kirsuberjamousse/">
                    Síggj meira
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="home-recipes__item">
            <div className="row">
              <div className="col-md-6">
                <img
                  className="img-fluid"
                  src={
                    require("assets/images/home-recipe-example-3.jpg").default
                  }
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <div className="home-recipes__item-inner">
                  <h3>
                    <span className="cat--desert"></span> FUDGEBROWNIE OSTAKØKA
                  </h3>
                  <p>
                    Einki er sum ein góð ostakaka. Enn betur verður hon við
                    einum lekkrum heimagjørdum botni. Her baka vit lekkru
                    sjokulátakakuna fudgebrownie og brúka hana sum botn í
                    ostakakuni.
                  </p>
                  <Button to="/fudgebrownie-ostakoka/">Síggj meira</Button>
                </div>
              </div>
            </div>
          </div>

          <div className="home-recipes__cta">
            <Button to="/uppskriftir/" className="button--reverse">
              Síggj fleiri uppskriftir
            </Button>
          </div>
        </div>
      </section>

      <span className="anchor" id="blog"></span>
      <section className="home-blog">
        <div className="container-fluid">
          <div className="global__header">
            <img
              className="header__logo"
              src={require("assets/images/home-recipes-header-ico.png").default}
              alt=""
            />
            <p>Okkara</p>
            <h2>TíĐindi</h2>
          </div>

          <Slider {...blogSlider}>
            {data.allWpPost.nodes.map((item, index) => (
              <div key={index}>
                <div className="home-blog__item">
                  <div
                    className="home-blog__item-image"
                    style={{
                      backgroundImage: `url('${
                        item?.featuredImage
                          ? item?.featuredImage?.node?.sourceUrl
                          : require("assets/images/home-thumbnail.jpg").default
                      }')`,
                    }}
                  ></div>
                  <div className="home-blog__item-inner">
                    <span>
                      {item.date.split("T")[0].split("-").reverse().join(".")}
                    </span>
                    <h4>{item.title}</h4>
                    <div dangerouslySetInnerHTML={{ __html: item.excerpt }} />
                    <Link to={item.uri}>Síggj meira</Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>

          <div className="home-blog__cta">
            <Button to="/tidindi/">TÍĐINDI</Button>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        uri
        date
        content
        excerpt
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
    homeFields: wpPage(id: { eq: "cG9zdDoxMTQy" }) {
      acfHome {
        homeHeroVideo
        homeHeroExternalUrl
        homeHeroVideoPoster {
          sourceUrl
        }
        homeHeroVideoPosterMobile {
          sourceUrl
        }
        homeAboutHeader
        homeAboutDescription
        homeAboutTile {
          homeAboutTileImage {
            sourceUrl
          }
          homeAboutTileLabel
          homeAboutTileHeader
          homeAboutTileExcerpt
          homeAboutTileUrlSlug
        }
        homeContentVideo
        homeProductsLogos {
          homeProductsLogoPermalink
          homeProductsLogoImage {
            sourceUrl
          }
        }
      }
    }
  }
`

export default Index
