import React from "react"
import Slider from "react-slick"
import { Link } from "gatsby"

import Button from "components/Button/Button"

const HomeBrands = ({ homeProductsLogos }) => {
  const brandsSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  }

  return (
    <section className="home-brands">
      <div className="home-brands__wrapper">
        <Slider {...brandsSlider}>
          {homeProductsLogos.map((item, index) => (
            <div key={index}>
              <Link to={`/${item.homeProductsLogoPermalink}/`}>
                <img
                  className="img-fluid"
                  src={item.homeProductsLogoImage.sourceUrl}
                  alt=""
                />
              </Link>
            </div>
          ))}
        </Slider>
        <Button to="/vorur/" className="button--light">
          Síggj meira
        </Button>
      </div>
    </section>
  )
}

export default HomeBrands
